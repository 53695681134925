import React, { useState } from 'react';
import { setUserSession } from '../../components/userdata/UserData';
import axios from 'axios';
import './login.css';

function Login(props) {
	
	const username = useFormInput('');
	const password = useFormInput('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
 
	const handleLogin = () => {
	    setError(null);
	    setLoading(true);	   
	    let url = 'https://core.hom-bpp.com.br/authorizationserver/oauth2/password/token';
		const input = {username: username.value, secret: password.value};
	    fetch(url, {
	    	  "method": "POST",
	    	  "headers": {
	    	    "content-type": "application/json"
	    	  },
	    	  "body": JSON.stringify(input)
	    	})
	    	.then(response => response.json())
	    	.then(data => {
	    		if (data.access_token) {
	    			setLoading(false);
	    			const auth = {"scope": data.scope, "token": data.access_token};
				    setUserSession(data.access_token, auth);
		    		console.log(data);
				    props.history.push('/');
	    		} else {
	    			setLoading(false);
					console.info('Usuario NOK !');
	    		}
	    	})
	    	.catch(err => {
	    		setLoading(false);
	    		console.log("Erro no login: " + err);
	    	});
    }
 
	if (loading) {
		return <div>Loading...</div>
	}
	
	return (<div className="container">
		        <h1 className="form-heading">login Form</h1>
				<div className="login-form">
				    <div className="main-div">
				        <div className="panel">
				            <h2>Login na Conta</h2>
				            <p>Por favor entre com seu email e senha</p>
				        </div>
				        <form id="Login">
				        	<div className="form-group">
				            	<input type="text" {...username} className="form-control" id="inputEmail" placeholder="Email" />
		
				            </div>
				            <div className="form-group">
				              	<input type="password" {...password} className="form-control" id="inputPassword" placeholder="Senha" />
				            </div>
				            <div className="forgot">
				            	<a href="#">Esqueceu a senha ?</a>
				            </div>
				            <input type="button" className="btn btn-primary" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} />
				        </form>
				    </div>
				</div>
			</div>);	
}
 
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;