import React, { useState, useEffect } from 'react';
import FixedHeader from '../../components/header/Index';
import { getToken } from '../../components/userdata/UserData';
import { Card, Table, Modal, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import C3Chart from 'react-c3js';
import 'c3/c3.css';

function Dashboard(props) {
	
	const token = getToken();
	const [show, setShow] = useState(false);
	const [startDate, setStartDate] = useState(new Date());
	const [traceTransactionsMatera, setTraceTransactionsMatera] = useState();
	const [traceTransactions, setTraceTransactions] = useState();
	const [detailMateraTrace, setDetailMateraTrace] = useState({"requestId":""});
	const [counterTed, setCounterTed] = useState({"success":"", "error":""});
	const [counterTransfer, setCounterTransfer] = useState({"success":"", "error":""});
	const [counterCharge, setCounterCharge] = useState({"success":"", "error":""});
	const [counterDischarge, setCounterDischarge] = useState({"success":"", "error":""});
	const data = {columns: [['data1', 30, 200, 100, 400, 150, 250], ['data2', 50, 20, 10, 40, 15, 25]]};
	const dataTotalRequests = {x: 'x', columns: [
	            ['x', 'TED', 'Transferencias', 'Pagamentos', 'Carga Cartao', 'Descarga Cartao'],
	            ['Sucesso', 30, 200, 100, 400, 150],
	            ['Erro', 50, 250, 80, 350, 200]], type: 'bar'};
	const axisTotalRequests = {
	        rotated: true,
	        x: {
	            type: 'category'
	        }
	    };

	const handleClose = () =>  setShow(false);
	
	const handleShow = (requestId) => {
		console.log('requestId:' + requestId);
		fetch("https://core.hom-bpp.com.br/coreadminshim/trace/" + requestId, {method: 'GET', headers: {'Authorization': token}})
		.then(response => response.json())
		.then(data => { console.log(data); setDetailMateraTrace(data); })
		.catch(err => { console.log(err); });
		setShow(true);
	}
		  	  
    useEffect(() => {
    	let today = new Date();
		let dd = today.getDate();
		let mm = today.getMonth()+1; 
		let yyyy = today.getFullYear();
		let formatted;		  
		if (mm>9) {
			if (dd>9) {
				formatted = yyyy+'-'+mm+'-'+dd;				  
			} else {
				formatted = yyyy+'-'+mm+'-0'+dd;
			}
		} else {
			if (dd>9) {
				formatted = yyyy+'-0'+mm+'-'+dd;
			} else {
				formatted = yyyy+'-0'+mm+'-0'+dd;
			}			  
		}
		requestCounters(formatted);
		mountTableDistributedTracing();
		mountTableDistributedTracingMatera();
	}, []);	
    
	const mountTableDistributedTracing = () => {
		console.log('mountTableDistributedTracing: ' + token);
		fetch("https://core.hom-bpp.com.br/coreadminshim/transactions/latest/500", {method: 'GET', headers: {'Authorization': token}})
		.then(response => response.json())
		.then(data => {
			let traces = data.traces;
			const x = traces.map((element, index) => {
				return (
				    <tr>
				      <td>{element.timestamp}</td>
				      <td>{element.api}</td>
				      <td>{element.operation}</td>
				      <td>{element.value}</td>
				      <td>
				      	  <div style={{textAlign:"center"}}>
				      	  	  <FontAwesomeIcon icon={faSearchPlus} onClick={() => handleShow(element.requestId)} />
				      	  </div>
				      </td>
				    </tr>
				  )		    	  
			});
			setTraceTransactions(x);
		})
		.catch(err => {
			console.log(err);
		});
	}
    
	const mountTableDistributedTracingMatera = () => {
		fetch("https://core.hom-bpp.com.br/coreadminshim/trace/materaintegrator/lasts", {method: 'GET', headers: {'Authorization': token}})
		.then(response => response.json())
		.then(data => {
			const x = data.map((element, index) => {
				let bgcolor = '#ffffff';
				if (element.latency > 1000) {
					bgcolor = '#ffcccc';
				}
				return (
				    <tr style={{backgroundColor: bgcolor}}>
				      <td>{element.timestamp}</td>
				      <td>{element.url}</td>
				      <td>{element.statusCode}</td>
				      <td>{element.latency}</td>
				      <td>
				      	  <div style={{textAlign:"center"}}>
				      	  	  <FontAwesomeIcon icon={faSearchPlus} onClick={() => handleShow(element.requestId)} />
				      	  </div>
				      </td>
				    </tr>
				  )		    	  
			});
			setTraceTransactionsMatera(x);
		})
		.catch(err => {
			console.log(err);
		});		
	}
    
	const mountMateraIntegratorRecords = (traces) => {
		let x = "";
		if (traces) {
			x = traces.map((element, index) => {
				return (
					<div>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>URL:</b></Col>
			        	    <Col sm={9}>{element.url}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Method:</b></Col>
			        	    <Col sm={9}>{element.method}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Status Code:</b></Col>
			        	    <Col sm={9}>{element.statusCode}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Latência:</b></Col>
			        	    <Col sm={9}>{element.latency} ms</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Request:</b></Col>
			        	    <Col sm={9}>{element.request}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Response:</b></Col>
			        	    <Col sm={9}>{element.response}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Timestamp:</b></Col>
			        	    <Col sm={9}>{element.timestamp}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>RequestId:</b></Col>
			        	    <Col sm={9}>{element.requestId}</Col>
			        	</Row>
						<Row>
			        	    <Col sm={12}><hr /></Col>
			        	</Row>
		        	</div>		        	
				  )		    	  
			});
		}
		return x;
	}
    
	const handleChange = date => {
		setStartDate(date);
		let dd = date.getDate();
		let mm = date.getMonth()+1; 
		let yyyy = date.getFullYear();
		let formatted;		  
		if (mm>9) {
			if (dd>9) {
				formatted = yyyy+'-'+mm+'-'+dd;				  
			} else {
				formatted = yyyy+'-'+mm+'-0'+dd;
			}
		} else {
			if (dd>9) {
				formatted = yyyy+'-0'+mm+'-'+dd;
			} else {
				formatted = yyyy+'-0'+mm+'-0'+dd;
			}			  
		}
		requestCounters(formatted);
	}
	  
	const requestCounters = formatted => {
		let urlTed = "https://core.hom-bpp.com.br/coreadminshim/metrics/ted/counter/" + formatted;
		let urlTransfer = "https://core.hom-bpp.com.br/coreadminshim/metrics/transfer/counter/" + formatted;
		let urlCharge = "https://core.hom-bpp.com.br/coreadminshim/metrics/charge/counter/" + formatted;
		let urlDischarge = "https://core.hom-bpp.com.br/coreadminshim/metrics/discharge/counter/" + formatted;
		fetch(urlTed, {method: 'GET', headers: {'Authorization': token}}).then(response => response.json()).then(data => setCounterTed(data)).catch(err => console.log(err));
		fetch(urlTransfer, {method: 'GET', headers: {'Authorization': token}}).then(response => response.json()).then(data => setCounterTransfer(data)).catch(err => console.log(err));
		fetch(urlCharge, {method: 'GET', headers: {'Authorization': token}}).then(response => response.json()).then(data => setCounterCharge(data)).catch(err => console.log(err));
		fetch(urlDischarge, {method: 'GET', headers: {'Authorization': token}}).then(response => response.json()).then(data => setCounterDischarge(data)).catch(err => console.log(err));
	}
	
	const popupDetailTraceMatera = () => {
		const traces = mountMateraIntegratorRecords(detailMateraTrace.materaIntegrator);
		let api, operation, endpoint, input = "";
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.api) {
			api = detailMateraTrace.inputRequest.api;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.operation) {
			operation = detailMateraTrace.inputRequest.operation;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.endpoint) {
			endpoint = detailMateraTrace.inputRequest.endpoint;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.input) {
			input = detailMateraTrace.inputRequest.input;
		}
    	return <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
		    	<Modal.Header closeButton>
		    		<Modal.Title>Detalhes do Trace</Modal.Title>
		    	</Modal.Header>
		    	<Modal.Body>
		        	<Card>
		        	  <Card.Body>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>RequestId:</b></Col>
			        	    <Col sm={9}>{detailMateraTrace.requestId}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>API:</b></Col>
			        	    <Col sm={9}>{api}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>Operação:</b></Col>
			        	    <Col sm={9}>{operation}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>Endpoint:</b></Col>
			        	    <Col sm={9}>{endpoint}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>JSON Entrada:</b></Col>
			        	    <Col sm={9}>{input}</Col>
			        	  </Row>
		        	  </Card.Body>
		        	</Card>
		        	<Card>
		        	  <Card.Body>
			        	  <Row>
			        	    <Col sm={12}><u>Requisições para Matera</u><br/><br/></Col>
			        	  </Row>
			        	  {traces}
		        	  </Card.Body>
		        	</Card>
		    	</Modal.Body>
		    	<Modal.Footer>
		    		<Button variant="primary" onClick={handleClose}>Fechar</Button>
		    	</Modal.Footer>
		    </Modal>
	}
	  
	return <div>
    		<FixedHeader />
    		<div className="row" id="body-row">
				<div className="col p-4">				
					<div className="row">
						<div className="col-md-12">
							Data selecionada: <DatePicker selected={startDate} onChange={handleChange} dateFormat="yyyy-MM-dd" />
	    				</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-md-6">
							<C3Chart data={dataTotalRequests} axis={axisTotalRequests} />
						</div>
						<div className="col-md-6">
							<Table bordered hover responsive size="sm">
							  <thead>
							    <tr>
							      <th style={{backgroundColor: '#99ccff'}}>Operação</th>
							      <th style={{backgroundColor: '#99ccff', textAlign:"center"}}>Sucesso</th>
							      <th style={{backgroundColor: '#99ccff', textAlign:"center"}}>Erro</th>
							      <th style={{backgroundColor: '#99ccff', textAlign:"center"}}>Total</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr>
							      <td>TED</td>
							      <td style={{textAlign:"center"}}>{counterTed.success}</td>
							      <td style={{textAlign:"center"}}>{counterTed.error}</td>
							      <td style={{textAlign:"center"}}>{parseInt(counterTed.success) + parseInt(counterTed.error)}</td>
							    </tr>
							    <tr>
							      <td>Transferências internas</td>
							      <td style={{textAlign:"center"}}>{counterTransfer.success}</td>
							      <td style={{textAlign:"center"}}>{counterTransfer.error}</td>
							      <td style={{textAlign:"center"}}>{parseInt(counterTransfer.success) + parseInt(counterTransfer.error)}</td>
							    </tr>
							    <tr>
							      <td>Pagamentos</td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							    </tr>
							    <tr>
							      <td>Carga de cartão</td>
							      <td style={{textAlign:"center"}}>{counterCharge.success}</td>
							      <td style={{textAlign:"center"}}>{counterCharge.error}</td>
							      <td style={{textAlign:"center"}}>{parseInt(counterCharge.success) + parseInt(counterCharge.error)}</td>
							    </tr>
							    <tr>
							      <td>Descarga de cartão</td>
							      <td style={{textAlign:"center"}}>{counterDischarge.success}</td>
							      <td style={{textAlign:"center"}}>{counterDischarge.error}</td>
							      <td style={{textAlign:"center"}}>{parseInt(counterDischarge.success) + parseInt(counterDischarge.error)}</td>
							    </tr>
							    <tr>
							      <td>Saldo</td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							    </tr>
							    <tr>
							      <td>Extrato</td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							      <td style={{textAlign:"center"}}></td>
							    </tr>
							  </tbody>
							</Table>					
						</div>
					</div>
					<hr />
					<div className="row">
						<div className="col-md-6">
							<div className="span33">
								<Table responsive bordered hover size="sm">
								  <thead>
								    <tr>
								      <th colSpan="5" style={{backgroundColor: '#f0f0f0', textAlign:"center"}}><b>Últimas requisicoes (trace)</b></th>
								    </tr>
								  </thead>
								  <tbody>
										{traceTransactions}
								  </tbody>
								</Table>
							</div>
						</div>
						<div className="col-md-6">
							<div className="span33">
								<Table responsive bordered hover size="sm">
								  <thead>
								    <tr>
								      <th colSpan="5" style={{backgroundColor: '#f0f0f0', textAlign:"center"}}><b>Últimas requisicoes para Matera</b></th>
								    </tr>
								  </thead>
								  <tbody>
										{traceTransactionsMatera}
								  </tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
			{popupDetailTraceMatera()}
		</div>;		   
}

export default Dashboard;
