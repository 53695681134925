import React, { useState } from 'react';
import FixedHeader from '../../components/header/Index';
import { Card, Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";

function TED(props) {
	
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [teds, setTeds] = useState();
	  	  
	const handleChangeStartDate = date => {
		setStartDate(date);
	}
	  
	const handleChangeEndDate = date => {
		setEndDate(date);
	}
	
	const handleClean = () => {
		setTeds();
	}
	  
	const handleSearch = () => {
		let initialDate = formatDate(startDate);
		let finalDate = formatDate(endDate);
		fetch("https://core.hom-bpp.com.br/coreadminshim/metrics/ted/search/"+initialDate+"/"+finalDate, {
			"method": "GET"
		})
		.then(response => response.json())
		.then(data => {
			let json = data;
			const x = json.items.map((element, index) => {
				return (
				    <tr>
				      <td>{element.timestamp}</td>
				      <td>{element.account}</td>
				      <td>{element.value}</td>
				      <td>{element.date}</td>
				      <td>{element.name}</td>
				      <td>
				      	  <div style={{textAlign:"center"}}>
				      	  	  <FontAwesomeIcon icon={faSearchPlus} />
				      	  </div>
				      </td>
				    </tr>
				  )		    	  
			});
			setTeds(x);    
		})
		.catch(err => {
			console.log(err);
		});		
	}
	  
	const formatDate = date => {
		let dd = date.getDate();
		let mm = date.getMonth()+1; 
		let yyyy = date.getFullYear();
		let formatted;		  
		if (mm>9) {
			if (dd>9) {
				formatted = yyyy+'-'+mm+'-'+dd;				  
			} else {
				formatted = yyyy+'-'+mm+'-0'+dd;
			}
		} else {
			if (dd>9) {
				formatted = yyyy+'-0'+mm+'-'+dd;
			} else {
				formatted = yyyy+'-0'+mm+'-0'+dd;
			}			  
		}
		return formatted;
	}

	return <div>
    		<FixedHeader />
    		<div className="row" id="body-row">
				<div className="col p-4">			
					<h1>Consulta de TEDs</h1>
					<Card>
						<Card.Body>
							<Container fluid="md">
					        	<Row>
					        	    <Col sm={2} style={{textAlign:"right"}}>Data inicial:</Col>
					        	    <Col sm={10}><DatePicker selected={startDate} onChange={handleChangeStartDate} dateFormat="yyyy-MM-dd" /></Col>
					        	</Row>
					        	<Row>
					        	    <Col sm={2} style={{textAlign:"right"}}>Data final:</Col>
					        	    <Col sm={10}><DatePicker selected={endDate} onChange={handleChangeEndDate} dateFormat="yyyy-MM-dd" /></Col>
					        	</Row>						
					        	<Row>
					        		<Col sm={12}>&nbsp;</Col>
					        	</Row>						
					        	<Row>
				        	    <Col sm={2}></Col>
					        	    <Col sm={10}>
					        	    	<Button variant="primary" onClick={handleSearch}>Consultar</Button>
					        	    	&nbsp;
					        	    	<Button variant="primary" onClick={handleClean}>Limpar</Button>
					        	    </Col>
					        	</Row>						
				        	</Container>
				        	<br />
							<Table responsive bordered hover size="sm">
							  <thead>
							    <tr>
							      <th style={{backgroundColor: '#99ccff'}}>Timestamp</th>
							      <th style={{backgroundColor: '#99ccff'}}>Conta</th>
							      <th style={{backgroundColor: '#99ccff'}}>Valor</th>
							      <th style={{backgroundColor: '#99ccff'}}>Data Matera</th>
							      <th style={{backgroundColor: '#99ccff'}}>Nome</th>
							      <th style={{backgroundColor: '#99ccff'}}></th>
							    </tr>
							  </thead>
							  <tbody>
							    {teds}
							  </tbody>
							</Table>
						</Card.Body>
					</Card>				
				</div>
			</div>
		</div>;		   
}

export default TED;
