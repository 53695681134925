import React, { useState } from 'react';
import FixedHeader from '../../components/header/Index';
import { getToken } from '../../components/userdata/UserData';
import { Modal, Card, Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";

function MateraIntegrator(props) {
	
	const token = getToken();
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	const [records, setRecords] = useState();
	const [show, setShow] = useState(false);
	const [detailMateraTrace, setDetailMateraTrace] = useState({"requestId":""});
	  	  
	const handleChangeStartDate = date => {
		setStartDate(date);
	}
	  
	const handleChangeEndDate = date => {
		setEndDate(date);
	}
	
	const handleClean = () => {
		setRecords();
	}
	  
	const handleSearch = () => {
		let initialDate = formatDate(startDate);
		let finalDate = formatDate(endDate);
		fetch("https://core.hom-bpp.com.br/coreadminshim/trace/materaintegrator/"+initialDate+"/"+finalDate, {
			method: 'GET', 
			headers: {'Authorization': token
		}})
		.then(response => response.json())
		.then(data => {
			let json = data;
			const x = json.map((element, index) => {
				return (
				    <tr>
				      <td>{element.timestamp}</td>
				      <td>{element.statusCode}</td>
				      <td>{element.latency}</td>
				      <td>{element.method}</td>
				      <td>{element.url}</td>
				      <td>
				      	  <div style={{textAlign:"center"}}>
				      	  	  <FontAwesomeIcon icon={faSearchPlus} onClick={() => handleShow(element.requestId)} />
				      	  </div>
				      </td>
				    </tr>
				  )		    	  
			});
			setRecords(x);    
		})
		.catch(err => {
			console.log(err);
		});		
	}
	  
	const formatDate = date => {
//		let sec = date.getSeconds();
//		let min = date.getMinutes();
//		let hour = date.getHours();
//		let dd = date.getDate();
//		let mm = date.getMonth()+1; 
//		let yyyy = date.getFullYear();
//		let formatted;		  
//		if (mm>9) {
//			if (dd>9) {
//				formatted = yyyy+'-'+mm+'-'+dd;				  
//			} else {
//				formatted = yyyy+'-'+mm+'-0'+dd;
//			}
//		} else {
//			if (dd>9) {
//				formatted = yyyy+'-0'+mm+'-'+dd;
//			} else {
//				formatted = yyyy+'-0'+mm+'-0'+dd;
//			}			  
//		}
		let formatted = date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate()) + " " + appendLeadingZeroes(date.getHours()) + ":" + appendLeadingZeroes(date.getMinutes()) + ":" + appendLeadingZeroes(date.getSeconds());
//		formatted = formatted + " " + appendLeadingZeroes(hour) + ":" + appendLeadingZeroes(min) + ":" + appendLeadingZeroes(sec);
		return formatted;
	}
	
	const appendLeadingZeroes =  n => {
		if(n <= 9){
			return "0" + n;
		}
		return n;
	}	
	
	const handleClose = () =>  setShow(false);
	
	const handleShow = (requestId) => {
		console.log('requestId:' + requestId);
		fetch("https://core.hom-bpp.com.br/coreadminshim/trace/" + requestId, {'method': 'GET', headers: {'Authorization': token}})
		.then(response => response.json())
		.then(data => { console.log(data); setDetailMateraTrace(data); })
		.catch(err => { console.log(err); });
		setShow(true);
	}
	
	const mountMateraIntegratorRecords = (traces) => {
		let x = "";
		if (traces) {
			x = traces.map((element, index) => {
				return (
					<div>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>URL:</b></Col>
			        	    <Col sm={9}>{element.url}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Method:</b></Col>
			        	    <Col sm={9}>{element.method}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Status Code:</b></Col>
			        	    <Col sm={9}>{element.statusCode}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Latência:</b></Col>
			        	    <Col sm={9}>{element.latency} ms</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Request:</b></Col>
			        	    <Col sm={9}>{element.request}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Response:</b></Col>
			        	    <Col sm={9}>{element.response}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>Timestamp:</b></Col>
			        	    <Col sm={9}>{element.timestamp}</Col>
			        	</Row>
						<Row>
			        		<Col sm={3} style={{textAlign:"right"}}><b>RequestId:</b></Col>
			        	    <Col sm={9}>{element.requestId}</Col>
			        	</Row>
						<Row>
			        	    <Col sm={12}><hr /></Col>
			        	</Row>
		        	</div>		        	
				  )		    	  
			});
		}
		return x;
	}
    
	const popupDetailTraceMatera = () => {
		const traces = mountMateraIntegratorRecords(detailMateraTrace.materaIntegrator);
		let api, operation, endpoint, input = "";
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.api) {
			api = detailMateraTrace.inputRequest.api;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.operation) {
			operation = detailMateraTrace.inputRequest.operation;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.endpoint) {
			endpoint = detailMateraTrace.inputRequest.endpoint;
		}
		if (detailMateraTrace.inputRequest && detailMateraTrace.inputRequest.input) {
			input = detailMateraTrace.inputRequest.input;
		}
    	return <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
		    	<Modal.Header closeButton>
		    		<Modal.Title>Detalhes do Trace</Modal.Title>
		    	</Modal.Header>
		    	<Modal.Body>
		        	<Card>
		        	  <Card.Body>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>RequestId:</b></Col>
			        	    <Col sm={9}>{detailMateraTrace.requestId}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>API:</b></Col>
			        	    <Col sm={9}>{api}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>Operação:</b></Col>
			        	    <Col sm={9}>{operation}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>Endpoint:</b></Col>
			        	    <Col sm={9}>{endpoint}</Col>
			        	  </Row>
			        	  <Row>
			        	    <Col sm={3} style={{textAlign:"right"}}><b>JSON Entrada:</b></Col>
			        	    <Col sm={9}>{input}</Col>
			        	  </Row>
		        	  </Card.Body>
		        	</Card>
		        	<Card>
		        	  <Card.Body>
			        	  <Row>
			        	    <Col sm={12}><u>Requisições para Matera</u><br/><br/></Col>
			        	  </Row>
			        	  {traces}
		        	  </Card.Body>
		        	</Card>
		    	</Modal.Body>
		    	<Modal.Footer>
		    		<Button variant="primary" onClick={handleClose}>Fechar</Button>
		    	</Modal.Footer>
		    </Modal>
	}	

	return <div>
    		<FixedHeader />
    		<div className="row" id="body-row">
				<div className="col p-4">			
					<h1>Consulta de requests enviados para Matera</h1>
					<Card>
						<Card.Body>
							<Container fluid="md">
					        	<Row>
					        	    <Col sm={2} style={{textAlign:"right"}}>Data inicial:</Col>
					        	    <Col sm={10}><DatePicker selected={startDate} onChange={handleChangeStartDate} dateFormat="yyyy-MM-dd HH:mm:ss" /></Col>
					        	</Row>
					        	<Row>
					        	    <Col sm={2} style={{textAlign:"right"}}>Data final:</Col>
					        	    <Col sm={10}><DatePicker selected={endDate} onChange={handleChangeEndDate} dateFormat="yyyy-MM-dd HH:mm:ss" /></Col>
					        	</Row>						
					        	<Row>
					        		<Col sm={12}>&nbsp;</Col>
					        	</Row>						
					        	<Row>
				        	    <Col sm={2}></Col>
					        	    <Col sm={10}>
					        	    	<Button variant="primary" onClick={handleSearch}>Consultar</Button>
					        	    	&nbsp;
					        	    	<Button variant="primary" onClick={handleClean}>Limpar</Button>
					        	    </Col>
					        	</Row>						
				        	</Container>
				        	<br />
							<Table responsive bordered hover size="sm">
							  <thead>
							    <tr>
							      <th style={{backgroundColor: '#99ccff'}}>Timestamp</th>
							      <th style={{backgroundColor: '#99ccff'}}>Status Code</th>
							      <th style={{backgroundColor: '#99ccff'}}>Latência</th>
							      <th style={{backgroundColor: '#99ccff'}}>Método</th>
							      <th style={{backgroundColor: '#99ccff'}}>URL</th>
							      <th style={{backgroundColor: '#99ccff'}}></th>
							    </tr>
							  </thead>
							  <tbody>
							    {records}
							  </tbody>
							</Table>
						</Card.Body>
					</Card>				
				</div>
			</div>
			{popupDetailTraceMatera()}
		</div>;		   
}

export default MateraIntegrator;
