import React from 'react';
import { removeUserSession } from '../userdata/UserData';
import { Link } from 'react-router-dom';

export default function FixedHeader(props) {
	
	const handleLogout = () => {
		removeUserSession();
		props.history.push('/login');
	}

	return <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
			<Link to="/">
				<a className="navbar-brand" href="#">
					<img src="../../assets/bppLogo.svg" width="30" height="30" className="d-inline-block align-top" alt="" />
					Core Admin
				</a>
			</Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			    <span className="navbar-toggler-icon"></span>
			</button>
		
			<div className="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul className="navbar-nav mr-auto">
			      	<li className="nav-item active">
			      		<Link to="/">
			      			<a className="nav-link" href="#">Dashboard <span className="sr-only">(current)</span></a>
			      		</Link>
			      	</li>
			      	<li className="nav-item">
		      			<Link to="/ted">
		      				<a className="nav-link" href="#">TED</a>
	      				</Link>
			      	</li>
			      	<li className="nav-item">
		      			<Link to="/materaintegrator">
		      				<a className="nav-link" href="#">Matera</a>
	      				</Link>
			      	</li>
			      	<li className="nav-item">
			      		<a className="nav-link" href="#" onClick={handleLogout}>Logout</a>
			      	</li>
			    </ul>
			</div>
		</nav>;

}
