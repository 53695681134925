import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import logo from './logo.svg';

import './css/cards.css';
import './css/menu.css';
import './css/panel.css';
import './css/table.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "react-datepicker/dist/react-datepicker.css";

import { getToken, removeUserSession, setUserSession } from './components/userdata/UserData';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import Dashboard from './screens/dashboard/Index.js';
import TED from './screens/ted/Index.js';
import MateraIntegrator from './screens/materaintegrator/Index.js';
import Login from './screens/login/Index.js';

function App(props) {

	  const [authLoading, setAuthLoading] = useState(true);
	  
	  useEffect(() => {
		  const token = getToken();
	      if (!token) {
	          return;
	      }
	      console.log(token);
	      let url = 'https://core.hom-bpp.com.br/authorizationserver/oauth2/password/validate';
	      fetch(url, {
	    	  "method": "POST",
	    	  "headers": {
	    	    "content-type": "application/json",
	    	    "Authorization": token
	    	  }
	    	})
	    	.then(response => response.json())
	    	.then(data => {
	    		console.log('true: ' + data);
	    		setAuthLoading(false);
	    		if (data.status) {
	    			console.log('validate ok');
	    		} else {
	    			console.log('validate nok');
	    		    removeUserSession();
				    props.history.push('/');
	    		}
	    	})
	    	.catch(err => {
	    		setAuthLoading(false);
  		      	removeUserSession();
			    props.history.push('/');
	    	});	      
	  }, []);
	 
	  if (authLoading && getToken()) {
		  return <div className="content">Checking Authentication...</div>
	  }
		
	  return <Router>
				<Switch>
			      	<PrivateRoute exact path="/" component={Dashboard} />
			      	<PrivateRoute path="/ted" component={TED} />
			      	<PrivateRoute path="/materaintegrator" component={MateraIntegrator} />
			      	<PublicRoute path="/login" component={Login} />
		      	</Switch>
			</Router>;	

}

export default App;
